<template>
  <div class="auth-wrapper auth-v1 px-2 bg-login">
    <div
      class="auth-inner py-2"
    >
      <b-card class="mb-0">
        <b-link class="brand-logo mb-1 mt-0">
          <b-img
            :src="require('@/assets/images/devida/logo-ppptcd.jpeg')"
            width="250"
          />
        </b-link>

        <b-card-title class="mb-0">
          <center><b>Plataforma de Sistema de Monitoreo de Prevención y Tratamiento de Consumo de Drogas en las II.EE focalizadas!</b></center>
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="usuario"
              label="Usuario"
            >
              <validation-provider
                #default="{ errors }"
                name="Usuario"
                rules="required"
              >
                <b-form-input
                  id="usuario"
                  v-model="userEmail"
                  name="login-usuario"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Usuario"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Recordarme
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Ingresar
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>

    </div>
    <div class="flotante">
      <b-card class="mb-0">
        <b-card-title
          class="mb-0"
          style="font-size: 14px !important;"
        >
          <b>Información Pública de Interés</b>
        </b-card-title>
        <p style="margin-top: 20px;">
          <b-img
            src="/pdf.png"
            height="auto"
            width="20"
            class="mr-1"
          />
          <a
            href="/modulo-iv-sesiones.pdf"
            target="_blank"
            style="color: #6e6b7b; font-size: 0.8rem"
          >
            <span class="align-bottom">
              MÓDULO IV: SESIONES TUTORÍA
            </span>
          </a>
        </p>
        <p>
          <b-img
            src="/pdf.png"
            height="auto"
            width="20"
            class="mr-1"
          />
          <a
            href="/instructivo.pdf"
            target="_blank"
            style="color: #6e6b7b; font-size: 0.8rem"
          >
            <span class="align-bottom">
              INSTRUCTIVO TAREA N° 1.6
            </span>
          </a>
        </p>
      </b-card>
    </div>>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import store from '@/store/index'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getAbilityByRol, getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      token: '',
      userData: {},
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  methods: {
    login() {
      store.dispatch('auth/AUTH_CREATE_TOKEN', {
        username: this.userEmail,
        password: this.password,
      })
        .then(response => {
          const { user, token } = response

          useJwt.setToken(token)
          useJwt.setRefreshToken(token)
          console.log(user)
          user.ability = getAbilityByRol(user.authorities[0].authority)
          localStorage.setItem('userData', JSON.stringify(user))
          localStorage.setItem('userProfile', user.authorities[0].authority)

          this.$ability.update([{ action: 'manage', subject: 'all' }])

          this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 5)

          this.$router.push(getHomeRouteForLoggedInUser('admin'))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Bienvenido ${user.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `Has iniciado sesión con éxito como ${user.authorities.authority}. Ahora puedes empezar a trabajar!`,
                },
              })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        })
        .catch(error => {
          let errorMessage = 'Intentelo en otro momento.'
          if (error.response) {
            errorMessage = error.response.data.errorMessage
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ha ocurrido un error',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: errorMessage,
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.bg-login{
  background-image: url('~@/assets/images/devida/fondo-login.jpeg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.flotante {
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: #3498db;
  color: #fff;
  padding: 10px;
}
</style>
